import React, { useEffect, useState } from 'react';

import { useLazyQuery } from '@apollo/client';
import { navigate } from 'gatsby';
import { getEnvConfig } from 'src/config/config';
import { LoginError, userIsGlobalAdmin } from 'src/utils/auth';
import { fetchCurrentUser } from 'src/utils/classic-api';
import { storeLTAToken, storeLTAUser, storeToken, storeUser, storeUsername } from 'src/utils/storage/local-storage';

import { Spinner } from '@clubspark-react/clubspark-react-tools';

import { GET_LTA_USER } from './oauth-callback-queries';

export interface LTAUser {
  userName: string;
  accessToken: string;
  userEmail?: string | null;
  userID?: string | null;
  sfContactID?: string | null;
}

const OAuthCallback: React.FC = () => {
  const [getLTAUser, { data, error }] = useLazyQuery(GET_LTA_USER);

  useEffect(() => {
    if (data) {
      storeLTAUser(data.userAccessToken);
      if (userIsGlobalAdmin()) {
        navigate('/lta-memberships');
      }
    }
    if (error) {
      navigate('/');
    }
  }, [data, error]);

  useEffect(() => {
    (async () => {
      const res = await fetch(`${getEnvConfig().LTA_SSO_TOKENS_URL}`, { credentials: 'include' });
      const LTAtoken = res.headers.get('x-sso-token');
      const token = res.headers.get('x-api-token');
      if (token && LTAtoken) {
        storeLTAToken(LTAtoken ?? '');
        storeToken(token);
        const res = await fetchCurrentUser();

        if (res.status === 401 || res.status === 404) {
          navigate('/');
        }
        const user = await res.json();

        if (!user) {
          navigate('/');
        }
        storeUser(user);
        getLTAUser({
          variables: { accessToken: LTAtoken },
        });
      }
    })();
  }, []);

  return <Spinner />;
};

export default OAuthCallback;

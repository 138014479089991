import graphql from 'graphql-tag';

// SSO
export const GET_LTA_USER = graphql`
  query userAccessToken($accessToken: String!) {
    userAccessToken(accessToken: $accessToken) {
      userEmail
      sfContactID
      userFullName
    }
  }
`;
